import React from 'react';
import { Container, Typography, Paper, CardMedia, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import MenuBar from './components/appBar';
import Footer from './components/footer';

const Histoire = () => {
  const { t } = useTranslation();

  return (
    <div>
      <MenuBar/>
      <Container maxWidth="md" style={{ marginTop: '5%', marginBottom: '5%' }}>
        <Paper elevation={3} style={{ padding: '24px', marginBottom: '24px', borderRadius: '15px' }}>
          <Typography variant="h4" gutterBottom align="center" color="primary" style={{ fontWeight: 'bold' }}>
            {t('ourStory')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                height="100%"
                image="/images/content/logo/logo_certifit-2.png"
                alt=""
                style={{ borderRadius: '10px', marginBottom: '24px' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph>
              {t('storyPageDesc')}<br/>
              {t('storyPageDesc2')}
              <br/><br/>
           </Typography>
            </Grid>
          </Grid>
          <Typography variant="body1" paragraph>
            {t('storyPageDesc3')}
          <br/> <br/>
            {t('storyPageDesc4')}
          <br/> <br/>
            {t('storyPageDesc5')}
          <br/> <br/>
            {t('storyPageDesc6')}<br/>
          </Typography>
       {/*   <Typography variant="h5" gutterBottom align="center" color="primary" style={{ fontWeight: 'bold', marginTop: '24px' }}>
            Les Créateurs
          </Typography>
          
          <Grid container spacing={2} alignItems="center" style={{ marginBottom: '24px' }}>
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
              <Typography variant="body1">
                <strong>Dorian</strong> : Description brève...
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
              <CardMedia
                component="img"
                height="100%"
                image="/images/content/dorian.jpeg"
                alt="Créateur 1"
                style={{ borderRadius: '10px', width: '100%' }}
              />
            </Grid>
          </Grid>
          
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6} order={{ xs: 1 }}>
              <CardMedia
                component="img"
                height="100%"
                image="/images/content/hugo.JPG"
                alt="Créateur 2"
                style={{ borderRadius: '10px', width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 2 }}>
              <Typography variant="body1">
                <strong>Hugo Le Hen - Spécialiste en Technologies Numériques</strong> : <br/>
                Actuellement en troisième année de Bachelor Universitaire de Technologie (BUT) en Informatique en alternance, il apporte une expertise technique et une passion pour l'innovation à l'équipe Certifit-Motion. <br/>
                 Sa participation active aux décisions stratégiques de l'entreprise enrichit notre vision avec une perspective jeune et technologique. Passionné par l'application de l'innovation technologique dans le monde des affaires, Hugo est déterminé à contribuer au succès et à la croissance de Certifit-Motion, en partageant notre vision et nos projets avec enthousiasme et conviction.
              </Typography>
            </Grid>
          </Grid>
        */} 
        </Paper>
      </Container>
      <Footer />
    </div>
  );
}


export default Histoire;
