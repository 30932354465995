import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import MenuBar from '../../content/components/appBar';
import Footer from '../../content/components/footer';
import { Container } from '@mui/material';

const MusclePageTemplate = () => {  
    const { muscleName } = useParams();
    const [selectedMuscle, setSelectedMuscle] = useState(null);
    const [isLoading, setIsLoading] = useState(true); 
    const url = "";
    const navigate = useNavigate();
    const posi = [47.760138, -3.244150];
    useEffect(() => {
      setIsLoading(true); 
      fetch(`${process.env.REACT_APP_API_URL}/muscles/${muscleName}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('La requête a échoué');
          }
          return response.json();
        })
        .then((data) => {
          setSelectedMuscle(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Erreur lors de la requête GET : ', error);
   
        });
    }, [muscleName, navigate]);

    if (isLoading) {
      return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
          </div>
      );
  }

  return (
    <div>
      <MenuBar />
      <Container maxWidth="md">
      <div style={{margin:'5%'}}>
      <h1 style={{textAlign:'center'}}>   {selectedMuscle && (
            selectedMuscle.name
          )}  </h1>
      </div>
      
      {selectedMuscle && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={`/images/muscles/${selectedMuscle.url}.png`} style={{ width: '80%' }} alt="Muscle Image" />
          </div>
        )}


            {selectedMuscle && selectedMuscle.description && selectedMuscle.description.length > 0 && (
            <ul>
                {selectedMuscle.description.map((description, index) => (
                <ul key={index} style={{margin:'3%', textAlign:'center', fontSize:'110%'}}>{description}</ul> 
                ))}
            </ul>
            )}
      </Container>
      <Footer />
    </div>
  );
}

export default MusclePageTemplate;
