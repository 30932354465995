import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import WebFont from 'webfontloader'; 
import './i18n'; 

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Lato, sans-serif',
  },
});

function Main() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Playfair Display', 'Chilanka']
      }
    });
  }, []);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);

reportWebVitals();
