import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, List, ListItem, Divider, ListItemText, CircularProgress } from '@mui/material';

export const ListExoByEquipment = (props) => {
    const { equipmentUrl } = props;
    const { gymUrl } = props;
    const [exercices, setExercices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}/exercices/equip/${equipmentUrl}`;
    
        const fetchData = async () => {
          try {
            const response = await fetch(url);
            const json = await response.json();
            setExercices(Object.values(json));
            setIsLoading(false);
            
          } catch (error) {
            console.log('error', error);
          }
        };
    
        fetchData();
      }, []);
   


    const handleListItemClick = (exerciceName) => {
        navigate(`/editExercice/${equipmentUrl}/${exerciceName}`);
     };
    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div style={{ margin: '5%' }}>

            <h3 style={{ textAlign: 'center' }}>Liste des Exercices de la machine <br/>(cliquer dessus pour le modifier)</h3>
          
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', maxHeight: '400px', overflow: 'auto' }}>
                {exercices.map((exercice, index) => (
                    <ListItem
                        alignItems="flex-start"
                        key={index}
                        onClick={() => handleListItemClick(exercice.name)}
                        sx={{
                            "&:hover": {
                                backgroundColor: "#f0f0f0",
                            },
                        }}
                    >
                        <ListItemText
                            primary={exercice.name}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {exercice.description.first}
                                    </Typography>
                                    <br/>
                                    <Divider/>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </div>
    );
};
