import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box, Grid, Accordion, AccordionDetails, AccordionSummary, CircularProgress, Skeleton } from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';

import Footer from './../components/footer';
import MenuBar from '../components/appBar';
import MuscleCard from './muscleCard';

export const ExerciceTemplate = () => {
  const { exerciceName } = useParams();
  const { equipmentUrl } = useParams();
  const { gymUrl } = useParams();
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [selectedExercice, setSelectedExercice] = useState(null);
  const navigate = useNavigate();

  const navigateToEquipment = () => {
    navigate(`/equipment/${gymUrl}/${equipmentUrl}`);
  };
  useEffect(() => {
    const fetchExerciceData = async () => {
      try {
        const exerciceResponse = await fetch(`${process.env.REACT_APP_API_URL}/exercices/exo/${exerciceName}/${equipmentUrl}`);
        if (!exerciceResponse.ok) {
          throw new Error('La requête a échoué pour les données de l\'exercice');
        }
        const exerciceData = await exerciceResponse.json();
       
        setSelectedExercice(exerciceData);
          const equipmentResponse = await fetch(`${process.env.REACT_APP_API_URL}/equipments/${equipmentUrl}`);
          if (!equipmentResponse.ok) {
            throw new Error('La requête a échoué pour les données de l\'équipement');
          }
          const equipmentData = await equipmentResponse.json();
          setSelectedEquipment(equipmentData);
      } catch (error) {
        console.error('Erreur lors de la requête GET : ', error);
   // navigate(`/exerciceNotFound/${exerciceName}`);
      }
    };

    fetchExerciceData();
  }, [exerciceName, navigate]);

  return (
    <div>
      <MenuBar />
     <div style={{ margin: '5%' }}>
     { selectedExercice && selectedEquipment ? (
     
        <p style={{fontSize:'80%'}}>
        <span onClick={navigateToEquipment}> {selectedEquipment ? selectedEquipment.name : ''}</span> &gt; {selectedExercice ? selectedExercice.name : ''}
        </p>) : (
          <div> 
            <Skeleton variant="text" width={300} />
            
          </div>
        )}

        <Box sx={{ width: '100%' }}>
        { selectedExercice  && selectedEquipment ? (
      <h1 style={{ textAlign: 'center' }}>{selectedExercice ? selectedExercice.name : ''}</h1>
        ): (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
         
          <Skeleton variant="text" width={350} height={50} />
        <Skeleton variant="rectangular" width={460} height={800} />
          </div>
        )}
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          
          <Box
      sx={{
        width: '100%', 
        maxWidth: 460, 
        position: 'relative',
        '&::before': {
          content: '""',
          display: 'block',
          paddingTop: '177.77%',
        },
        '& iframe': {
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
          border: '1px solid #000',
          borderRadius: '18px',
        },
      }}
    >
      { selectedExercice && selectedEquipment ? (
 <Vimeo
 video={selectedExercice.linkVideo}
 autoplay
 onReady={() => console.log('Vimeo player is ready')}
 onError={(error) => console.log('Vimeo player error:', error)}
/>
     
      ): (<div> 
       </div>)}
        
        </Box>
          </div>

          <Grid container spacing={3} style={{alignItems:'center'}}>
           <Grid item xs={12} md={6} style={{marginTop:'3%'}}>
          
                <Typography variant="" style={{fontSize:'115%', fontFamily:''}}>
            
                {selectedExercice && selectedExercice.description && selectedExercice.description.length > 0 && (
                 <ul>
                    {selectedExercice.description.map((description, index) => (
                    <ul key={index}style={{margin:'4%', textAlign:'center', fontFamily:''}}>{description}</ul> 
                    ))}
                </ul>
                )}
   </Typography>
             
            </Grid> 
            <Grid item xs={12} md={6} style={{marginTop:'3%'}}>
            { selectedExercice && selectedEquipment ? (
    
           <div sx={{ maxWidth: 400 }}>
      <Accordion>
        <AccordionSummary>Tips & Astuces</AccordionSummary>
        <AccordionDetails style={{fontSize:'90%'}}>
        {selectedExercice && selectedExercice.tips && selectedExercice.tips.length > 0 && (
  <ul>
    {selectedExercice.tips.map((tip, index) => (
      <li key={index} style={{margin:'2%'}}>{tip}</li> 
    ))}
  </ul>
)}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Avantages</AccordionSummary>
        <AccordionDetails style={{fontSize:'90%'}}>
          
        {selectedExercice && selectedExercice.tips && selectedExercice.tips.length > 0 && (
  <ul>
    {selectedExercice.advantages.map((tip, index) => (
      <li key={index} style={{margin:'2%'}}>{tip}</li> 
    ))}
  </ul>
)}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Explications</AccordionSummary>
        <AccordionDetails>
        <ul>
    {selectedExercice.explications.map((tip, index) => (
      <ul key={index} style={{margin:'2%'}}>{tip}</ul> 
    ))}
  </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
      <AccordionSummary>Articles</AccordionSummary>
      <AccordionDetails style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
      {selectedExercice.muscle && <MuscleCard muscle={selectedExercice.muscle} />}
      {selectedExercice.muscles && selectedExercice.muscles.map((muscle, index) => (
      muscle !== selectedExercice.muscle && <MuscleCard key={index} muscle={muscle} />
      ))}
      </AccordionDetails>
      </Accordion>
      
    </div>):(<></>)}
    </Grid>
          </Grid>
        </Box>
      </div>
      <Footer />
    </div>
  );
};