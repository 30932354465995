import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MenuItem, Dialog, DialogTitle, Button, List, ListItem, Divider, ListItemText, Skeleton, CardActionArea, CardActions, Card, Typography, CardMedia, CardContent, Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Footer from './../components/footer';
import MenuBar from '../components/appBar';

export const Equipments = () => {
  const { equipmentUrl } = useParams();
  const { gymUrl } = useParams();
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [selectedExercice, setSelectedExercice] = useState(null);
  const [selectedGym, setSelectedGym] = useState(null);
  const [muscles, setMuscles] = useState([]);
  const [selectedMuscle, setSelectedMuscle] = useState(''); 
  const [selectedMuscleIndex, setSelectedMuscleIndex] = useState(null);
  const handleCardClick = (index) => {
    const muscleSelected = muscles[index];
    setSelectedMuscleIndex(selectedMuscleIndex === index ? null : index);
    setSelectedMuscle(muscleSelected === selectedMuscle ? '' : muscleSelected);
  };
  const [isMuscle, setIsMuscle] = useState(false);

  
  const cardSize = '300px';
  const [isDialogOpen, setIsDialogOpen] = useState(false);



  const [sortCriteria, setSortCriteria] = useState('name');
  const navigate = useNavigate();

  const handleListItemClick = (exerciceName) => {
     navigate(`/exercice/${gymUrl}/${equipmentUrl}/${exerciceName}`);
  };
  const navigateToGym = () => {
    navigate(`/salle/${gymUrl}`);
 };
 const sortEquipment = (criteria) => {
  if (!selectedExercice) return;

  let sortedEquipment = [...selectedExercice];
  if (criteria === 'name') {
      sortedEquipment.sort((a, b) => a.name.localeCompare(b.name));
  } else if (criteria === 'muscle') {
      sortedEquipment.sort((a, b) => a.muscle.localeCompare(b.muscle));
  }
  setSelectedExercice(sortedEquipment);
  setSortCriteria(criteria);
};

  useEffect(() => { 
    const fetchEquip = fetch(`${process.env.REACT_APP_API_URL}/equipments/equip/${equipmentUrl}/${gymUrl}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('La requête a échoué');
        }
        return response.json();
      })
      .then((data) => {
        setSelectedEquipment(data);
       // sortEquipment(sortCriteria);
      })
      .catch((error) => {
        console.error('Erreur lors de la requête GET : ', error);
      //  navigate(`/exerciceNotFound/${equipmentUrl}`);
      });

    fetchEquip;
  }, [equipmentUrl]);

  useEffect(() => {
    const fetchEquip = fetch(`${process.env.REACT_APP_API_URL}/gyms/${gymUrl}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('La requête a échoué');
        }
        return response.json();
      })
      .then((data) => {
        setSelectedGym(data);
      })
      .catch((error) => {
        console.error('Erreur lors de la requête GET : ', error);
      });

    fetchEquip;
  }, [equipmentUrl]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/exercices/equip/${equipmentUrl}`;

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json(); 
        const exercises = Object.values(json);
        setSelectedExercice(exercises);
      /*  if (exercises.length === 1) {
          navigate(`/exercice/${gymUrl}/${equipmentUrl}/${exercises[0].name}`);
        }*/
        setMuscles([...new Set(exercises.map(ex => ex.muscle))]); 
        
      } catch (error) {
        console.log('error', error);
     //   navigate(`/exerciceNotFound/${equipmentUrl}`);
      }
    };

    fetchData();
  }, []);

  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const handleClickMuscle = () => {
    setIsMuscle(!isMuscle);
  }
  return (
    <div>
      <MenuBar />
      <div style={{ margin: '5%' }}>
        {selectedEquipment ? (
          <div> 
            <span onClick={() => navigateToGym()}>{selectedGym ? selectedGym.name : ''}</span> &gt; 
            {selectedEquipment ? selectedEquipment.name : ''}
          </div>
        ) : (
          <Skeleton variant="text" width={300} />
        )}
        {selectedEquipment ? (
          <h1 style={{ textAlign: 'center' }}>{selectedEquipment.name}</h1>
        ) : (
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
          <Skeleton variant="text" width={300} height={50} style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} />
          </div>
        )}
        <Button onClick={handleClickMuscle}> Afficher la liste des exercices</Button>
        <br/>
  {!isMuscle ? (
    <>
        <Typography variant="h6" gutterBottom>Liste des muscles travaillés</Typography>
       {selectedEquipment ? (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
          {muscles.map((muscle, index) => (
            <div key={index} style={{ width: `${cardSize}` }}>
              <Card onClick={() => handleCardClick(index)} style={{ border: 'none', boxShadow: 'none' }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="240"
                    image={`/images/muscles/${muscle}.png`}
                    alt={muscle}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6">{muscle}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              
              {selectedMuscleIndex === index && (
                <div style={{ padding: '10px' }}>
                  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {selectedExercice.filter(ex => ex.muscle === selectedMuscle).map((exercice, index) => (
                      <ListItem
                        key={index}
                        alignItems="flex-start"
                        onClick={() => handleListItemClick(exercice.name)}
                        sx={{ 
                          "&:hover": { backgroundColor: "#f0f0f0" },
                          border: "1px solid #808080",
                          borderRadius: "30px",
                          marginBottom: "5px",
                      }}
                      >
                        <ListItemText
                          primary={exercice.name}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.secondary"
                              >
                                Muscle ciblé : {exercice.muscle}
                              </Typography>
                              <br />
                     
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              )}
            </div>
          ))}
        </div> ) : (
          <>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
            <Skeleton variant="rect" width={300} height={300} style={{marginLeft:'1%', marginBottom:'1%'}} />
          </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
            <Skeleton variant="rect" width={300} height={30} style={{marginLeft:'1%', marginBottom:'3%'}} />
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
            <Skeleton variant="rect" width={300} height={300} style={{marginLeft:'1%', marginBottom:'1%'}} />
          </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
            <Skeleton variant="rect" width={300} height={30} style={{marginLeft:'1%'}} />
          </div>
          
          </>
        )}
        
        </>) : (<> 
        
          <SortIcon onClick={() => setIsDialogOpen(true)}>
    Sort
</SortIcon>

      <Dialog onClose={handleClose} open={isDialogOpen}>
      <DialogTitle>Trier par : </DialogTitle>
   
      <MenuItem onClick={() => { sortEquipment('name'); setIsDialogOpen(false); }}>Nom</MenuItem>
  <MenuItem onClick={() => { sortEquipment('muscle'); setIsDialogOpen(false); }}>Muscle</MenuItem>

   </Dialog>
   {selectedEquipment ? (
   <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
         
        </div>) : (
          <div style={{ display: 'flex', justifyContent: 'center'}}> 
            <Skeleton variant="rect" width={100} height={35} style={{marginLeft:'1%'}} />
            <Skeleton variant="rect" width={100} height={35} style={{marginLeft:'1%'}} />
            <Skeleton variant="rect" width={100} height={35} style={{marginLeft:'1%'}} />
            </div>
        )}

        {selectedEquipment ? (
              <div> 
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {selectedExercice &&
            selectedExercice.filter(ex => selectedMuscle === '' || ex.muscle === selectedMuscle).map((exercice, index) => (
        
              <ListItem
              key={index}
              alignItems="flex-start"
              onClick={() => handleListItemClick(exercice.name)}
              sx={{ 
                "&:hover": { backgroundColor: "#f0f0f0" },
                border: "1px solid #808080",
                borderRadius: "30px",
                marginBottom: "5px",
            }}
            >
        <ListItemText
          primary={exercice.name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
            Muscle ciblé :   {exercice.muscle}
              </Typography>
              <br/>
            </React.Fragment>
          }
        />
      </ListItem>
      ))}
    </List>
    </div> ) :(   <div>
    <Skeleton variant="text" width={330} height={110} />
    <Skeleton variant="text" width={330} height={110} />
    <Skeleton variant="text" width={330} height={110} />
    </div>)}

      
        
        </>)}
      </div>
      <Footer />
    </div>
  );  
  
};
