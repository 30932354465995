import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function QrCodeGen() {
    const [url, setUrl] = useState("");
    const navigate = useNavigate();

    const onSubmit = (e) => {
        e.preventDefault();


        axios.post(`${process.env.REACT_APP_API_URL}/qrcode/submit`,{url})
            .then((response) => {
                navigate("/");
                console.log(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div>
            <form onSubmit={onSubmit}>
                <input
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}
