import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Grid, useMediaQuery, useTheme, Typography } from '@mui/material';
import Skeleton from '@mui/lab/Skeleton';

import { ListEquipByGym } from '../../components/lists/listEquipByGym';
import MenuBar from '../../components/appBar';
import Footer from '../../components/footer';
import { Map } from '../../components/map';

const GymPageTemplate = () => {  
    const theme = useTheme();
    const [isOnMobile, setIsOnMobile] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const skeletonHeight = isSmallScreen ? 200 : 500;
    
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { gymUrl } = useParams();
    const [selectedGym, setSelectedGym] = useState(null);
   
    const navigate = useNavigate();
    useEffect(() => {


      fetch(`${process.env.REACT_APP_API_URL}/gyms/${gymUrl}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('La requête a échoué');
          }
          return response.json();
        })
        .then((data) => {
          setSelectedGym(data);
        })
        .catch((error) => {
          console.error('Erreur lors de la requête GET : ', error);
   
        });
    }, [gymUrl, navigate]);

    useEffect(() => {
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      setIsOnMobile(mediaQuery.matches);

      const handler = (event) => {
        setIsOnMobile(event.matches);
      };

      mediaQuery.addEventListener('change', handler);

      return () => mediaQuery.removeEventListener('change', handler);
    }, []);


  return (
    <div>
      <MenuBar/> 
      {selectedGym ? (<div> 
      <>
      {isMobile ? (
             
               <><div style={{ position: 'relative' }}>
          {selectedGym && (
            <img src={`/images/salles/${selectedGym.url}.png`} style={{ width: '100%' }} alt="Gym Image" />
          )}
         
        </div>
        <Typography variant="h5" style={{ textAlign: 'center', fontWeight:'bold' }}> {selectedGym ? selectedGym.name : ''}</Typography>
        <Typography style={{ textAlign: 'center' }}>{selectedGym ? selectedGym.description : ''}</Typography>
        <ListEquipByGym gymUrl={gymUrl} isMobile={isMobile} /><Grid item xs={12} md={6}>
            <Grid item xs={12} md={6}>

              <div style={{ height: '400px', marginBottom: '25%' }}>
                <Map position={selectedGym.address} label={selectedGym.name} />
              </div>
            </Grid>
          </Grid></>
              
             ) :(   
              <Container maxWidth="md">
      <div style={{ position: 'relative' }}>
            {selectedGym && (
              <img src={`/images/salles/${selectedGym.url}.png`} style={{ width: '100%' }} alt="Gym Image" />
            )}
            <h1 style={{ position: 'absolute', bottom: '3px', left: '0px', color: 'black', backgroundColor: 'white', padding: '5px', borderRadius: '0 10px 0 0' }}>
              {selectedGym ? selectedGym.name : ''}
            </h1>
          </div><Typography style={{ textAlign: 'center' }}>{selectedGym ? selectedGym.description : ''}
          </Typography>
          <ListEquipByGym gymUrl={gymUrl} isMobile={isOnMobile} />
          
          <Grid item xs={12} md={6}>
              <Grid item xs={12} md={6}>

              <div style={{ height: '300px', marginBottom: isMobile ? '80vh' : '40vh' }}> 
                  <Map position={selectedGym.address} label={selectedGym.name} />
              </div>

              </Grid>
            
            </Grid> 
            </Container>
              ) }
        </></div>):(<div> 
          <Container maxWidth="md">
     
              <Skeleton variant="rectangular" height={skeletonHeight} />
              <Skeleton variant="text"/>
              <Skeleton variant="text"/>
              <Skeleton variant="text" />
              <div style={{marginTop:'18%', marginBottom:'18%'}}>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom:'3%'}}>
                  <Skeleton variant="text" width={270} height={50} />
                </div>
                <Skeleton variant="rectangular" height={500} width={350} />
              </div>
              <Skeleton variant="rectangular" height={500} style={{marginBottom:'5%'}} />
            

          </Container>
        </div>)}
             

       
      <Footer />
    </div>
  );
}

export default GymPageTemplate;
