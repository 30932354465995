import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LatLng, Icon } from 'leaflet';

const myIcon = new Icon({
 iconUrl: '/images/content/marker.png',
 iconSize: [32,32]
})

export const Map = ({ label, position }) => {
    const latLng = new LatLng(position[0], position[1]);
    const adress = position[2];
    return (
        <div style={{ height: '450px', margin: '5%' }}> 
            <MapContainer center={latLng} zoom={15} style={{height:'100%', minHeight:'100%'}}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={latLng} icon={myIcon}>
                    <Popup>
                        {label}
                        <br/>
                       adresse :  {adress}
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    );
};