import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css'
import HomePage from './content/home';
import Product from './content/product';
import Contact from './content/contact';
import { ExerciceTemplate } from './content/template/exercice';
import { ListGym } from './content/components/lists/listGym';
import GymPageTemplate from './content/template/gym/gymPage';
import { ListCoachs } from './content/components/lists/listCoachs';
import { ListEquipByGym } from './content/components/lists/listEquipByGym';
import Histoire from './content/history';
import { Equipments } from './content/template/equipment';
import EquipNotFound from './content/error/equipNotFound';
import MusclePageTemplate from './content/template/muscle';
import PricingPage from './content/pricingPage';
import CreateExercices from './back-office/createExercices';
import EditExercise from './back-office/editExercice';
import CoachProfile from './content/template/coach/coachProfile';
import HomeCoach from './content/template/coach/homeCoach';
import CreateUserAdmin from './back-office/createUsers';
import DevisForm from './content/devis';
import OfferPresentation from './content/components/offerPresentation';
import OfferPresentationMini from './content/components/offer';
import QrCodeGen from './content/qrcode';

function App() {
  return (
<body>
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/qrcode/gen" element={<QrCodeGen />} />
        <Route path="/listGym" element={<ListGym />} />
        <Route path="/devis" element={<DevisForm/>}/>
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/listCoach" element={<ListCoachs />} />
        <Route path="/produit" element={<Product />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/equipment/:gymUrl/:equipmentUrl" element={<Equipments/>} />
        <Route path="/exercice/:gymUrl/:equipmentUrl/:exerciceName" element={<ExerciceTemplate/>} />
        <Route path="/salle/:gymUrl" element={<GymPageTemplate/>} />
        <Route path="/histoire" element={<Histoire/>} />
        <Route path="/equips" element={<EquipNotFound/>} />
        <Route path="/muscles/:muscleName" element={<MusclePageTemplate/>} />
        <Route path="/editExercice/:equipmentUrl/:exerciseName" element={<EditExercise/>}/>
        <Route path="/createExercice/:coachUrl/:gymUrl/:equipmentUrl" element={<CreateExercices/>} />
        <Route path="/coachprofile/:coachUrl" element={<CoachProfile/>} />
        <Route path="/homeprofile/:coachUrl" element={<HomeCoach/>}/>
        <Route path="/listEquipByGym/:coachUrl/:gymUrl" element={ <ListEquipByGym />} />
        <Route path="/createuser" element={ <CreateUserAdmin />} />
        <Route path="/offer" element={ <OfferPresentation />} />
      </Routes>
    </Router>
    </body>
  );
}

export default App;
