import React, { useState } from 'react';
import { Input } from '@mui/material';

function FilterBar({ onFilter, props }) {
  const text = props;
  const [query, setQuery] = useState('');

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    onFilter(event.target.value);
  };

  return (
    <Input
      type="text"
      placeholder={text}
      value={query}
      onChange={handleInputChange}
    />
  );
}

export default FilterBar;
