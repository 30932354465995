import React from 'react';
import { Typography, Container, ButtonGroup, Button } from '@mui/material';
import ArrowOutward from '@mui/icons-material/ArrowOutward';

import MenuBar from './components/appBar';
import Footer from './components/footer';
import Pricing from './components/pricing';

export default function PricingPage() {
  return (
      <div>
      <MenuBar/>
      <div style={{margin: "3%"}}>

      <Typography>
        <Typography variant="h3">
          Nos Solutions 
        </Typography>
        Certifit Motion vous propose plusieurs offres flexibles déclinant sa solution qui seront certainements combler vos attentes et vos besoins. Les offres chez certifit motion ont été conçue pour être totalement adapté aux besoins spécifiques de chaque salle.
       
       <br/><b> </b> 
      </Typography>
      </div>
        <Pricing/>

        <Container  maxWidth="md" style={{display:'flex', justifyContent:'center'}} >
      <div style={{textAlign:'center', marginBottom:'10vh', width: 620}}>

        <Typography>
        <Typography variant="h4" style={{color:'rgb(24,118,210)'}}>
             <b> Sûr de son Choix ?? </b>
        </Typography>
            Rejoins nous et tous nos collaborateurs dans l'aventure Certifit Motion 

        </Typography>
        <ButtonGroup variant="text" style={{ marginTop:'8%'}} aria-label="Basic button group">
        <Button href="/devis">
              Faire un devis     <ArrowOutward sx={{ fontSize: '2rem', color: 'rgb(25, 108, 210)' }} />
 
        </Button> 
        <Button   href="/contact">
                 Nous contacter      <ArrowOutward sx={{ fontSize: '2rem', color: 'rgb(25, 108, 210)' }} />
 
        </Button>
       </ButtonGroup>
      </div>
      </Container>
    <Footer/>
      </div>
)}



