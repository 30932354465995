import React, { useState, useEffect } from 'react';
import { Container, Grid, CircularProgress } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';


import { ListExoByCoach } from '../../components/lists/listExoByCoach';
import { ListGymsForCoachs } from '../../components/lists//listGymsForCoachs';
import Footer from  '../../components/footer';
import CoachProfile from './coachProfile';

const HomeCoach = () => {  
    const { coachUrl } = useParams();
    const [selectedCoach, setSelectedCoach] = useState(null);
    const [isLoading, setIsLoading] = useState(true); 
   
    const navigate = useNavigate();
    useEffect(() => {
      setIsLoading(true); 
      fetch(`${process.env.REACT_APP_API_URL}/coachs/${coachUrl}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('La requête a échoué');
          }
          return response.json();
        })
        .then((data) => {
          setSelectedCoach(data);
          setIsLoading(false);
          console.log(data)
        })
        .catch((error) => {
          console.error('Erreur lors de la requête GET : ', error);
   
        });
    }, [coachUrl, navigate]);

    if (isLoading) {
      return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
          </div>
      );
  }
  return (
    <div>
     <div >

        <CoachProfile/>
        <Container maxWidth='lg'>
        <Grid container spacing={5}>
         <Grid item xs={12} sm={6}>
        <ListExoByCoach listExo={selectedCoach.createdExercices} />
        </Grid>
        <Grid item xs={12} sm={6}>
        <ListGymsForCoachs coachUrl={selectedCoach.url}/>
      </Grid>
      </Grid>
      
      </Container>
      </div>
      <Footer />
    </div>
  );
}

export default HomeCoach;
