import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MenuBar from '../appBar';
import Footer from '../footer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import FilterBar from '../filterBar';
import ImageListItem from '@mui/material/ImageListItem';
import { useTheme, useMediaQuery, Grid } from '@mui/material';
import Skeleton from '@mui/lab/Skeleton';
import { useTranslation } from 'react-i18next';

export const ListGym = () => {
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const skeletonHeight = isSmallScreen ? 50 : 400;
    const skeletonspace = isSmallScreen ? '0' : '9vh';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [gyms, setGyms] = useState([]);
    const [filteredGyms, setFilteredGyms] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/gyms`)
            .then(response => response.json())
            .then(data => {
                const gymsArray = Object.values(data);
                setGyms(gymsArray);
                setFilteredGyms(gymsArray);
            })
            .catch(error => {
                console.error('Erreur lors de la requête GET : ', error);
            });
    }, []);

    const handleFilter = (query) => {
        const filtered = gyms.filter(gym =>
            gym.name.toLowerCase().includes(query.toLowerCase()) ||
            gym.description.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredGyms(filtered);
    };

    const handleListItemClick = (gymid) => {
        navigate(`/salle/${gymid}`);
    };

    return (
        <div>
            <MenuBar/>
            
            <Container maxWidth="l" style={{padding:'5%'}}>
            <Typography variant="h3" gutterBottom>
                {t('ourGyms')}
                    </Typography>
                <Paper elevation={3} style={{ padding: '24px', marginBottom: '24px' }}>
                <Typography variant="h6" gutterBottom>
                              {t('listGymsDesc')}
                </Typography>
                    <FilterBar onFilter={handleFilter} props={t('findGym')} />
                </Paper>
                
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {filteredGyms.length > 0 ? (  filteredGyms.map((gym, index) => (
                        
                        <Paper style={{ marginBottom: '24px' }}>
                
                {isMobile ? (
              <ListItem
              alignItems="flex-start"
              key={index}
              onClick={() => handleListItemClick(gym.url)}
              sx={{
                  "&:hover": {
                      backgroundColor: "#f0f0f0",
                  },
              }}
          >
             {/*   <img src={`/images/salles/${gym.url}.png`} style={{width:'auto', height:'50px', margin:'3%'}}/>  */}
              <ListItemText
                  style={{ marginTop: '3%', fontSize: '14px' }}
                primary={<Typography variant="h5">{gym.name}</Typography>}
                  secondary={
                      <React.Fragment>
                          <Typography variant="body2" color="text.primary">
                              {gym.description}
                         </Typography>
                      </React.Fragment>
                  }
              />
           
                
          </ListItem>
            
            ) :( 
                <ListItem
                            alignItems="flex-start"
                            key={index}
                            onClick={() => handleListItemClick(gym.url)}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                },
                            }}
                        >
                              <img src={`/images/salles/${gym.url}.png`} style={{width:'25%', height:'25%', margin:'3%'}}/>
                            <ListItemText
                                style={{ marginTop: '3%', fontSize: '14px' }}
                                primary={gym.name}
                                secondary={
                                    <React.Fragment>
                                        <Typography variant="body2" color="text.primary">
                                            {gym.description}
                                            <p style={{ marginTop: '3%' }}>{t('address')} : {gym.address[2]}</p>
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                         
                              
                        </ListItem>
                 ) }
                        </Paper>
                    ))
                    ) : (
                   
                        <div>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                <Skeleton variant="text" height={skeletonHeight} />
                            

                                </Grid>
                                <Grid item xs={12} sm={6} md={8} style={{marginTop:`${skeletonspace}`}}>
                                <Skeleton variant="text"  />
                                <Skeleton variant="text"  />
                                <Skeleton variant="text"  width={360}/>
                                <br/>
                                <br/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                <Skeleton variant="text" height={skeletonHeight} />
                            

                                </Grid>
                                <Grid item xs={12} sm={6} md={8} style={{marginTop:`${skeletonspace}`}}>
                                <Skeleton variant="text"  />
                                <Skeleton variant="text"  />
                                <Skeleton variant="text"  width={360}/>
                                <br/>
                                <br/>
                          
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                <Skeleton variant="text" height={skeletonHeight} />
                            

                                </Grid>
                                <Grid item xs={12} sm={6} md={8} style={{marginTop:`${skeletonspace}`}}>
                                <Skeleton variant="text"  />
                                <Skeleton variant="text"  />
                                <Skeleton variant="text"  width={360}/>
                          <br/>
                                <br/>
                                </Grid>
                            </Grid>
                          </div>
                          
                          )}
                </List>
            </Container>
            <Footer/>
        </div>
    );
};
