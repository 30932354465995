import React from 'react';
import { Container, Box, Typography, Button, Grid, Avatar, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import MenuBar from './appBar';
import Footer from './footer';

function OfferPresentation() {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
    <MenuBar/>
    <Container maxWidth="lg" sx={{ mt: 5, marginBottom:'5%' }}>
      <Grid container spacing={4} alignItems="center">
      {isMobile ? (
              <div style={{marginTop:'0%'}}></div>  ) :(  
        <Grid item xs={12} md={6}>
          <img src="/images/content/certifit_pres.png" alt="CertiFit Product" width="70%" style={{ borderRadius: '8px' }} />
        </Grid> ) }

        <Grid item xs={12} md={6}>
          <Typography variant="h3" gutterBottom>
            {t('certifitOffer')}
          </Typography>
          <Typography variant="body1" paragraph>
          {t('certifitOfferDesc')}
          </Typography>

          {/* Caractéristiques principales */}
          <Box>
            <Typography variant="h6" gutterBottom>
            {t('attributeDesc')}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
              <Typography>{t('attributeDesc1')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
              <Typography>{t('attributeDesc2')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
              <Typography>{t('attributeDesc3')}</Typography>
            </Box>
          </Box>
          {isMobile ? (
                     <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center'  }}>
                     <Button variant="contained" color="primary" sx={{ mr: 2 }} href="/devis">
                     {t('makeQuote')}
                     </Button>
                     <Button variant="outlined" color="primary" href="/contact">
                     {t('contactUs')}
                     </Button>
                   </Box>
                ) :(  
                    <Box sx={{ mt: 4}}>
                    <Button variant="contained" color="primary" sx={{ mr: 2 }} href="/devis">
                    {t('makeQuote')}
                    </Button>
                    <Button variant="outlined" color="primary" href="/contact">
                    {t('contactUs')}
                    </Button>
                  </Box>
      
                    ) }
      
        </Grid>
        {isMobile ? (
               <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
               <img src="/images/content/certifit_pres.png" alt="CertiFit Product" width="70%" style={{ borderRadius: '8px' }} />
             </Grid>     ) :(  
         <div style={{marginTop:'0%'}}></div>) }

      </Grid>

      <Box sx={{ mt: 10 }}>
        <Typography variant="h4" gutterBottom>
        {t('faq')}
        </Typography>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t('faq1')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {t('faq1_1')}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('faq2')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {t('faq2_1')}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>{t('faq3')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {t('faq3_1')}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
    <Footer/>
    </>
  );
}

export default OfferPresentation;
