import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, List, ListItem, Divider, ListItemText, CircularProgress, Button } from '@mui/material';
import FilterBar from '../filterBar';

export const ListEquipByGym = (props) => {
    const { gymUrl: paramGymUrl } = useParams();
    const { gymUrl: propGymUrl } = props;
    const { isMobile: isMobile } = props;
    const { coachUrl } = useParams();
    const gymUrl = paramGymUrl || propGymUrl;
    const [equipment, setEquipment] = useState([]);
    const [filteredEquipment, setFilteredEquipment] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/equipments/gyms/${gymUrl}`);
                const json = await response.json();
                const equipmentArray = Object.values(json);
                setEquipment(equipmentArray);
                setFilteredEquipment(equipmentArray);
                
                setIsLoading(false);
            } catch (error) {
                console.log('error', error);
            }
        };

        fetchData();
    }, [gymUrl]);
   
    
    const handleFilter = (query) => {
        const filtered = equipment.filter(eq =>
            eq.name.toLowerCase().includes(query.toLowerCase()) ||
            eq.description.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredEquipment(filtered);
    };

    const handleListItemClick = (equip) => {
     
        if(propGymUrl){
            if(isMobile){
                navigate(`/equipment/${gymUrl}/${equip}`);
            }
        }
        else{
            navigate(`/createExercice/${coachUrl}/${gymUrl}/${equip}`)
        }
    };

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div style={{ margin: '5%', marginBottom:'15%', marginTop:'15%' }}>
{equipment ? (
    <h3 style={{ textAlign: 'center' }}>
        Liste des machines de la salle: {filteredEquipment.length}
    </h3>
) : (
    <h3 style={{ textAlign: 'center' }}>Chargement...</h3>
)}
           
            <FilterBar onFilter={handleFilter} props={'Rechercher une machine...'} />
          
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', maxHeight: '400px', overflow: 'auto' }}>
                {filteredEquipment.map((equipment, index) => (
                    <ListItem
                        alignItems="flex-start"
                        key={index}
                        onClick={() => handleListItemClick(equipment.url)}
                        sx={{
                            "&:hover": {
                                backgroundColor: "#f0f0f0",
                            },
                        }}
                    >
                        <ListItemText
                            primary={equipment.name}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {equipment.description}
                                    </Typography>
                                    <br/>
                                    <Divider/>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                ))}
            </List>
            {!propGymUrl ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant='contained'> 
                        Créer une machine 
                    </Button>
                    </div>
            ): (
                <div></div>)}
         
        </div>
    );
};
