import React from 'react';

import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';

import MenuBar from '../components/appBar';
import Footer from '../components/footer';

const EquipNotFound = () => {
  const { equipmentUrl } = useParams();
  return (
    <div>
      <MenuBar />
      <div style={{ 
          backgroundImage: 'url(/images/content/error404.png)', 
          height: '500px',
          backgroundSize: 'cover',  
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'space-between'
        }}>
          <div style={{  marginTop:'25px', display:'flex' }}> 
            <h3 style={{textAlign:'center', marginTop:'auto' ,padding:'5%'}}>
              La machine <b>{equipmentUrl}</b> n'existe pas
            </h3> 
          </div>
          <Button variant="contained" style={{ alignSelf: 'flex-end', margin: '0 10px 10px 0' }} href="/listGym">
            Retourner à la liste des salles 
          </Button>
        </div>

      <Footer />
    </div>
  );
}

export default EquipNotFound;
