import * as React from 'react';
import { Container, GlobalStyles, Link, Typography, Grid, CssBaseline, CardHeader, CardContent, CardActions, Card, Button, Box, createTheme, ThemeProvider  } from '@mui/material';
import StarIcon from '@mui/icons-material/StarBorder';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const tiers = [
    
  {
    title: 'Site Web',
    subheader:"Application afin de guider l'utilisateur dans sa pratique",
    price: "~",
    description: [
      'Guide de mouvement',
      'Explications détaillées',
    ],
    buttonText: "Découvrir l'offre",
    buttonVariant: 'contained',
    buttonClick:'/'
  },
  {
    title: 'Application',
    subheader: 'En cours de développement',
    price: '~',
    description: [
      'Suivi des performances',
      'Programmes personnalisés',
     
    ],
    buttonText: 'A venir',
    buttonVariant: 'outlined',
    buttonClick:''
  },
  {
    title: 'Tests',
    subheader: '',
    price: '0',
    description: [
      "Faire un test GRATUIT de l'application web dans sa salle",
     
     
    ],
    buttonText: "Découvrir l'offre",
    buttonVariant: 'contained',
    buttonClick:'/contact'
  },
  
];


const tiersMobile = [
    
  {
    title: 'Site Web',
    subheader:"Application afin de guider l'utilisateur dans sa pratique",
    price: "~",
    description: [
      'Guide de mouvement',
      'Explications détaillées',
    ],
    buttonText: "Découvrir l'offre",
    buttonVariant: 'contained',
    buttonClick:'/'
  },
  {
    title: 'Application',
    subheader: 'En cours de développement',
    price: '~',
    description: [
      'Suivi des performances',
      'Programmes personnalisés',
     
    ],
    buttonText: 'A venir',
    buttonVariant: 'outlined',
    buttonClick:''
  }
];

const defaultTheme = createTheme();

export default function Pricing() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Nos Offres
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
         
        </Typography>
      </Container>
    
      <Container maxWidth="md" component="main" style={{marginBottom:'5%'}}>
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
         
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {tier.price}€
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant}  href={tier.buttonClick}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    
    </ThemeProvider>
  );
}





export function Pricingas() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6, padding:'10%' }}>
        
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Nos Offres
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
         
        </Typography>
      </Container>
    
      <Container maxWidth="md" component="main" style={{marginBottom:'5%'}}>
        <Grid container spacing={5} alignItems="flex-end">
          {tiersMobile.map((tier) => (
         
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {tier.price}€
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant}  href={tier.buttonClick}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    
    </ThemeProvider>
  );
}