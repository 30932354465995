import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, useMediaQuery, styled, Box, Container, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ZoomContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
}));

const ImageOverlayText = styled(Typography)({
  position: 'absolute',
  color: 'white',
  fontWeight: 'bold',
  textShadow: '2px 2px 8px rgba(0,0,0,0.8)',
  zIndex: 2,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'none',
});

const ImageOverlayTexts = styled(Typography)({
  position: 'absolute',
  color: 'white',
  zIndex: 2,
  top: 10,
  width: '100%',
  left: '50%',
  display: 'none',
  transform: 'translateX(-50%)',
  width: '100%',
  textAlign: 'center',
  color: 'white',
  zIndex: 2,
  padding: '0 10px',
});

const DescriptionText = styled(Typography)({
  position: 'absolute',
  top: 10,
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100%',
  textAlign: 'center',
  color: 'white',
  zIndex: 2,
  padding: '0 10px',
});

const ImageOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1,
});


const Partner = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isHovered, setIsHovered] = useState({ coach: false, gym: false });
    const navigate = useNavigate();
  
    const handleImageClick = (path) => {
      navigate(path);
    };
  
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" marginBottom={2}>
          <Typography variant="h4" textAlign="center">{t('ourPartner')}</Typography>  
        </Box>
        {isMobile ? (
               <Typography variant='body1' textAlign="center" style={{marginBottom:'3%'}}>{t('ourPartnerDesc')} </Typography>
               ) :(     <Typography variant='h6' textAlign="center" style={{marginBottom:'3%'}}> {t('ourPartnerDesc2')}  </Typography>
               ) }
    
        <Grid container spacing={2} justifyContent="center" alignItems="center">

          <Grid item xs={12} sm={6} onClick={() => handleImageClick('/listGym')} onMouseEnter={() => setIsHovered({ ...isHovered, gym: true })} onMouseLeave={() => setIsHovered({ ...isHovered, gym: false })}>

          <ZoomContainer>
              <Box component="img" src="/images/content/gymImage.webp" sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              <ImageOverlay />
             {isMobile ? (
             <ImageOverlayTexts variant="h6" style={{display: 'block'}}><span style={{textShadow: '2px 2px 2px rgba(0,0,0,0.8)', fontWeight: 'bold',fontSize:'110%'}}>{t('ourGyms')} </span></ImageOverlayTexts>
               ) :( 
               <div> {isHovered.gym ? <DescriptionText variant="subtitle1">{t('ourGymsDesc')} </DescriptionText> : <ImageOverlayText variant="h6" style={{display: 'block'}}>{t('ourGyms')}</ImageOverlayText>}
              </div>  ) }
             </ZoomContainer>
           
          </Grid>
  
          <Grid item xs={12} sm={6} onClick={() => handleImageClick('/listCoach')} onMouseEnter={() => setIsHovered({ ...isHovered, coach: true })} onMouseLeave={() => setIsHovered({ ...isHovered, coach: false })}>

          <ZoomContainer>
              <Box component="img" src="/images/content/coachImage.webp" sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              <ImageOverlay />
              {isMobile ? (
             <ImageOverlayTexts variant="h6" style={{display: 'block'}}><span style={{textShadow: '2px 2px 2px rgba(0,0,0,0.8)', fontWeight: 'bold',fontSize:'110%'}}> {t('ourCoaches')} </span> <br/><span style={{fontSize:'80%'}}>{t('ourCoachesDesc')}</span>  </ImageOverlayTexts>
               ) :( 
          <div>    {isHovered.coach ? <DescriptionText variant="subtitle1"> {t('ourCoachesDesc1')}<br/> {t('ourCoachesDesc2')}</DescriptionText> : <ImageOverlayText variant="h6" style={{display: 'block'}}>{t('ourCoaches')}</ImageOverlayText>}
              </div> )}
              </ZoomContainer>
          </Grid>
        </Grid>
      </Container>
    );
  };

    

export default Partner;