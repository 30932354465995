import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography, IconButton, Menu, MenuItem, Avatar, useMediaQuery, useTheme, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



function MenuBar() {
  const { t, i18n } = useTranslation();  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElLang, setAnchorElLang] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const settings = new Map([
    [t('offers'), '/offer'],
    [t("gyms"), '/listGym'],
    [t('coaches'), '/listCoach'],
    [t('contact'), '/contact'],
  ]);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenLangMenu = (event) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleCloseLangMenu();
  };

  return (
    <AppBar position="static" sx={{ background: 'rgb(24, 108, 210)' }}>
      <div style={{marginLeft:'3%'}}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
              <Avatar alt="Logo" src="/images/content/logo/logo_certifit_white.png" sx={{ width: 56, height: 56, marginRight: 1 }} />
              <Typography variant="h6" component="div" noWrap>
                CertiFit Motion
              </Typography>
            </Link>
          </Box>

          {isMobile ? (
            <>
              <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleOpenUserMenu}>
                <MenuIcon />
              </IconButton>
              <Menu sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorElUser} anchorOrigin={{ vertical: 'top', horizontal: 'right', }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right', }} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
                {Array.from(settings).map(([settingKey, settingValue]) => (
                  <MenuItem key={settingKey} onClick={handleCloseUserMenu}>
                    <Link to={settingValue} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Typography textAlign="center">{settingKey}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {Array.from(settings).map(([settingKey, settingValue]) => (
                <Link key={settingKey} to={settingValue} style={{ padding: '0 16px', color: 'white', textDecoration: 'none' }}>
                  <Typography textAlign="center">{settingKey}</Typography>
                </Link>
              ))}
            </Box>
          )}

          {/* Dropdown pour la sélection de langue */}
          <Box sx={{ ml: 2 }}>
            <Button
              onClick={handleOpenLangMenu}
              color="inherit"
              startIcon={<ArrowDropDownIcon />}
              sx={{ textTransform: 'none', color: 'white' }}
            >
              <Avatar alt="Langue" src={`/images/flags/${i18n.language}.png`} sx={{ width: 24, height: 24 }} />
            </Button>
            <Menu
              id="lang-menu"
              anchorEl={anchorElLang}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorElLang)}
              onClose={handleCloseLangMenu}
            >
              <MenuItem onClick={() => changeLanguage('fr')}>
                <Avatar alt="Français" src="/images/flags/fr.png" sx={{ width: 24, height: 24, marginRight: 1 }} />
                <Typography>Français</Typography>
              </MenuItem>
              <MenuItem onClick={() => changeLanguage('en')}>
                <Avatar alt="English" src="/images/flags/en.png" sx={{ width: 24, height: 24, marginRight: 1 }} />
                <Typography>English</Typography>
              </MenuItem>
              <MenuItem onClick={() => changeLanguage('de')}>
                <Avatar alt="Deutsch" src="/images/flags/de.png" sx={{ width: 24, height: 24, marginRight: 1 }} />
                <Typography>Deutsch</Typography>
              </MenuItem>
              <MenuItem onClick={() => changeLanguage('es')}>
                <Avatar alt="Español" src="/images/flags/es.png" sx={{ width: 24, height: 24, marginRight: 1 }} />
                <Typography>Español</Typography>
              </MenuItem>
            </Menu>
          </Box>
          
        </Toolbar>
      </div>
    </AppBar>
  );
}

export default MenuBar;
