import React, {useState} from 'react';
import { useTheme, useMediaQuery, Fab, Button, styled, Typography, Divider, Container } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowOutward from '@mui/icons-material/ArrowOutward';

import "./../App.css";
import MenuBar from './components/appBar';
import Footer from './components/footer';
import Partner from './components/partner';
import { useTranslation } from 'react-i18next';
import OfferPresentationMini from './components/offer';

const OutlinedFab = styled(Fab)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: 'none', 
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none', 
  },
  '&:focus': {
    boxShadow: 'none', 
  }
}));


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const HomePage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showTopBtn, setShowTopBtn] = React.useState(false);
  const [value, setValue] = useState('1');


  React.useEffect(() => {
    const handleScroll = () => {
      setShowTopBtn(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <MenuBar />
      <div>
        <div sx={{  marginLeft: '3%', marginRight: '3%'}}>
          <div style={{ marginTop: '5%', 
                backgroundSize: 'cover',  
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
              }}>
  

          <br/>
           <div style={{ padding:'5%', margin:'5%', backgroundColor:'white', borderRadius:'30px'}}>
            <div style={{padding:'2%'}}>
            {isMobile ? (
          
          
          <Typography variant="h8" style={{textAlign:'left'}}>
          <Typography variant='h3'><span style={{ color: 'rgb(24, 108, 210)' }}>{t('fitnessRevolution')}</span></Typography><br />
          {t('homePageDesc1')} <b>{t('appName')}.</b><br/>
          </Typography>
       
          ) :(     <Typography variant="h8" style={{textAlign:'left'}}>
          <Typography variant='h3'><span style={{ color: 'rgb(24, 108, 210)' }}>{t('fitnessRevolution')}</span> </Typography><br />
            {t('homePageDesc1')} <b>{t('appName')}.</b><br/>
            {t('homePageDesc2')}
          </Typography>
        ) }
          
           <div>
                  <Button variant="contained" style={{ marginTop: '3%' }} href="/produit" endIcon={  <ArrowOutward sx={{  color: 'white' }} />}>
                  {t('readMore')}
                    
                  </Button>
                </div>
             </div>
          </div>
        </div>

        
        {isMobile ? (
          <div> 
           <Divider orientation='horizontal' variant='middle' style={{marginBottom:'5%'}}/>   
            <Partner/> </div> ) :( <div style={{marginTop:'0%'}}></div>  ) }
          
                {isMobile ? (
              <div style={{marginTop:'3%', marginBottom:'3%'}}> <>   <Divider variant='middle'  sx={{ mt: 10 }} /> <OfferPresentationMini/> </></div>  ) :(   <div></div>) }
    
      <div style={{backgroundColor: isMobile ? 'white' : 'rgb(240,240,240)'}}>
      <div style={{ marginTop:'0%'}} >
                  <div style={{ padding:'8%' }}>
                  {isMobile ? (
              <div style={{marginTop:'0%'}}></div>  ) :(  <div style={{marginBottom:'0%'}}>  <Partner/> </div>   ) }
        
          
               </div></div>
                  </div> 
                  
            
      
                {isMobile ? (
              <div style={{marginTop:'0%'}}> </div>  ) :(  <>  <OfferPresentationMini/>  <Divider variant='middle' sx={{ mt: 10 }} /></>) }
      
          <br/>
          </div>
          <Container maxWidth="lg" sx={{ mt: 5, marginBottom:'5%' }}>
    
          {isMobile ? (
                 <></>
              ) :(  
                <Typography> 
                <Typography variant="h4">{t('ourStory')} </Typography>
                <br />
                {t('ourStoryDesc')}<br /><br />
                <Button href="/histoire" variant="text" style={{margin:'0%', color:'black'}} endIcon={  <ArrowOutward sx={{ }}/>}>{t('ourStoryButton')}</Button>
                </Typography>) }
          </Container>
          
           {showTopBtn && (
       <OutlinedFab
       color="primary"
       aria-label="scroll back to top"
       style={{
         position: 'fixed',
         bottom: '70px',
         right: '70px',
       }}
       onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
     >
       <ArrowUpwardIcon />
     </OutlinedFab>
     
      )}
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
