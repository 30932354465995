import React, { useState } from 'react';
import { Container, TextField, Button, Grid, Typography, ButtonGroup } from '@mui/material';
import ArrowOutward from '@mui/icons-material/ArrowOutward';
import { useTranslation } from 'react-i18next';

import MenuBar from './components/appBar';
import Footer from './components/footer';

const DevisForm = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    email:'',
    phoneNumber:'',
    NumberOfEquipment: '',
    address: ''
  });

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/devis`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValues),
      });

      if (!response.ok) {
        throw new Error('Something went wrong with the API');
      }

      const data = await response.json();
      console.log(data);
      alert('Devis soumis avec succès!');

    } catch (error) {
      console.error('Failed to submit the form', error);
    }
  };

  return (<div>
    <MenuBar/>
    <div style={{margin: "3%"}}>

    <Typography>
      <Typography variant="h3">
       {t('ourQuote')}
      </Typography>
      {t('ourQuoteDesc')} 
    
    <br/><b> </b> 
    </Typography>
    </div>
    <Container maxWidth="md" style={{marginTop:'5%', marginBottom:'5%'}}>
      <Typography variant="h4" gutterBottom textAlign="center">{t('formQuote')}</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('gymName')}
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('description')}
              name="description"
              value={formValues.description}
              multiline
              rows={4}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('numberMachine')}
              name="NumberOfEquipment"
              type="number"
              value={formValues.NumberOfEquipment}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('gymAdress')}
              name="address"
              value={formValues.address}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('gymEmail')}
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('gymPhone')}
              name="phoneNumber"
              value={formValues.phoneNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">{t('submitQuote')}</Button>
          </Grid>
        </Grid>
      </form>
    </Container>
    <Container  maxWidth="md" style={{display:'flex', justifyContent:'center'}} >
      <div style={{textAlign:'center', marginBottom:'10vh', width: 620}}>

        <Typography>
        <Typography variant="h4" style={{color:'rgb(24,118,210)'}}>
             <b> {t('questions')} </b>
        </Typography>
            {t('ourQuoteDesc2')}<br/>
            {t('ourQuoteDesc3')}

        </Typography>
        <ButtonGroup variant="text" style={{ marginTop:'8%'}} aria-label="Basic button group">
        <Button href="/contact">
        {t('contactUs')}     <ArrowOutward sx={{ fontSize: '2rem', color: 'rgb(25, 108, 210)' }} />
 
        </Button> 
        <Button   href="/pricing">
        {t('seeOffers')}      <ArrowOutward sx={{ fontSize: '2rem', color: 'rgb(25, 108, 210)' }} />
 
        </Button>
       </ButtonGroup>
      </div>
      </Container>
    
    <Footer/>
    </div>
  );
};

export default DevisForm;
