import React from 'react';

import { Divider, Button, Grid, Typography, useTheme, useMediaQuery, ButtonGroup } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';

import MenuBar from './components/appBar';
import Footer from './components/footer';

const Product = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

 
  return (

    <div>
  {isMobile ? (
              <div>  <ProductMobile/></div>  ) :(   <div> <ProductComputer/> </div>) }

    </div>
  );

}
const ProductComputer = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

 
  return (
      <div>
      <MenuBar/>
        <div style={{background: 'linear-gradient(to bottom, rgb(0, 123, 255), white)', padding:'2%'}}>
<div style={{margin:'5%', backgroundColor: 'white', padding:'1%', borderRadius:'20px'}}>
  <Typography color="rgb(0, 123, 255" variant="body2" style={{textAlign:'center', marginBottom:'5%'}}>
  <Typography  variant="h3"  style={{textAlign:'center'}}>
     {t('productPage')}
  </Typography>
    <br/>
      {t('productPageDesc')}
    <br/>
    {t('productPageDesc2')}
    <br/>
    
    </Typography>
    <div>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <div style={{marginLeft:'15%', marginTop:'5%'}}>
            <Typography variant='h6'>  {t('productPageDesc3')} </Typography>
            <DoneIcon style={{color:"rgb(0, 123, 255"}}/>  {t('productPageDesc4')}
            <br/>
            <DoneIcon style={{color:"rgb(0, 123, 255"}}/>  {t('productPageDesc5')}
            <br/>
            <DoneIcon style={{color:"rgb(0, 123, 255"}}/>  {t('productPageDesc6')}
            <br/>
            <DoneIcon style={{color:"rgb(0, 123, 255"}}/>  {t('productPageDesc7')}
          </div>
        </Grid>
        <Grid item xs={2}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={6}>
          <Typography style={{margin:"5%", textAlign:'center'}}>
          {t('productPageDesc8')} <span style={{color:"rgb(0, 123, 255"}}>  {t('productPageDesc9')}</span>  {t('productPageDesc10')}
            </Typography>
        </Grid>
      </Grid>
    </div>
  
    <Typography  style={{textAlign:'center'}}>
          <br/>

          <div style={{margin:'5%', textAlign:'left'}}>
          <h3><span style={{color:'rgb(24, 108, 210)'}}><b> {t('productPageDesc11')}</b></span></h3><br/> <br/>
          {t('productPageDesc12')}
          <span style={{color:'rgb(24, 108, 210)'}}><b>  {t('productPageDesc9')}</b></span> {t('productPageDesc13')}
          {t('productPageDesc14')}<br/>
          {t('productPageDesc15')} <span style={{color:'rgb(24, 108, 210)'}}><b> 
            {t('productPageDesc9')}</b></span>
            {t('productPageDesc16')}
            <br/><br/>

          <h3 style={{color:'rgb(24, 108, 210)'}}>
          {t('productPageDesc17')}
          </h3>
    
        <div style={{textAlign: "center" }}>
          <Button variant="outlined" style={{ margin:'3%'}}  href="/devis">
          {t('makeQuote')}    <ArrowOutwardIcon sx={{ fontSize: '2rem', color: 'rgb(25, 108, 210)' }} />
   
          </Button> 
          <Button variant="outlined" style={{ margin:'3%'}}  href="/pricing">
          {t('seeOffers')}      <ArrowOutwardIcon sx={{ fontSize: '2rem', color: 'rgb(25, 108, 210)' }} />
   
          </Button>
          <Button variant="outlined" style={{ margin:'3%'}}  href="/pricing">
          {t('contactUs')}   <ArrowOutwardIcon sx={{ fontSize: '2rem', color: 'rgb(25, 108, 210)' }} />
   
          </Button>
        </div>
        </div>

    
    </Typography>
   </div>
  
      

    </div>
    <Footer/>

    </div>
  );
}

const ProductMobile = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
    <MenuBar/>
      <div style={{background: 'linear-gradient(to bottom, rgb(0, 123, 255), white)', padding:'2%'}}>
  <div style={{margin:'5%', backgroundColor: 'white', padding:'1%', borderRadius:'20px'}}>
  <Typography color="rgb(0, 123, 255" variant="body2" style={{textAlign:'center', marginBottom:'5%', margin:'2%'}}>
  <Typography variant="h6" style={{ textAlign: 'center', fontWeight: 'bold' }}>
  {t('productPage')}
  </Typography>
  <br/>
      {t('productPageDesc')}
    <br/>
    {t('productPageDesc2')}
    <br/>
  
  </Typography>
  <div>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{marginTop:'5%', marginBottom: '5%'}}>
          <Typography variant='h6'>  {t('productPageDesc3')}</Typography>
          <DoneIcon style={{color:"rgb(0, 123, 255"}}/>  {t('productPageDesc4')}
          <br/>
          <DoneIcon style={{color:"rgb(0, 123, 255"}}/>  {t('productPageDesc5')}
          <br/>
          <DoneIcon style={{color:"rgb(0, 123, 255"}}/>  {t('productPageDesc6')}
          <br/>
          <DoneIcon style={{color:"rgb(0, 123, 255"}}/>  {t('productPageDesc7')} 
        </div>
      
        </div>
        <Divider orientation="horizontal" style={{margin:"3%"}} />
       <Typography style={{margin:"5%"}}>
       {t('productPageDesc8')}<span style={{color:"rgb(0, 123, 255"}}> {t('productPageDesc9')} </span>{t('productPageDesc10')}
          </Typography>
          </div>

  <div style={{ display: 'flex', justifyContent: 'center' }}>
  <Button variant="outlined" style={{ alignItems:'center'}}  href="/pricing" endIcon={<ArrowOutwardIcon sx={{ color: 'rgb(25, 108, 210)' }} />}>
        {t('contactUs')}
        </Button>
        </div>
  <Typography color="rgb(0, 123, 255" style={{textAlign:'center'}}>
        <br/>

        <div style={{margin:'5%', textAlign:'left'}}>
        <h3><span style={{color:'rgb(24, 108, 210)'}}><b>{t('productPageDesc11')}</b></span></h3><br/> <br/>
        {t('productPageDesc12')}<span style={{color:'rgb(24, 108, 210)'}}><b>{t('productPageDesc9')}</b></span>{t('productPageDesc13')}<br/>

        {t('productPageDesc15')} <span style={{color:'rgb(24, 108, 210)'}}><b>{t('productPageDesc9')}</b></span>
        {t('productPageDesc16')}<br/><br/>
        <div style={{textAlign: "center" }}>
        <Typography variant="h6" style={{color:'rgb(24, 108, 210)'}}>
          {t('productPageDesc17')}</Typography>
  

      <ButtonGroup variant="text" style={{ marginTop:'8%'}} aria-label="Basic button group">
        <Button href="/devis">
        {t('makeQuote')}   <ArrowOutwardIcon sx={{ fontSize: '2rem', color: 'rgb(25, 108, 210)' }} />
 
        </Button> 
        <Button   href="/pricing">
        {t('seeOffers')}      <ArrowOutwardIcon sx={{ fontSize: '2rem', color: 'rgb(25, 108, 210)' }} />
 
        </Button>
       </ButtonGroup>
      </div>
      </div>

  
  </Typography>
 </div>

    

  </div>
  <Footer/>

  </div>
);
}

export default Product;
