import * as React from "react";
import { Box, Grid, Link, Typography, Container } from "@mui/material";

import { Instagram } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";



export default function Footer() {
  const { t } = useTranslation();  

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
             {t('aboutUs')}
            </Typography>
            <Typography variant="body2" color="text.secondary"> 
            {t('companyDescription')}
</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
            {t('contactUs')}
            </Typography>
        
            <Typography variant="body2" color="text.secondary">
              Email: contact@certifit-motion.com
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Instagram: certifitmotion
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
            {t('followUs')}
            </Typography>
           
            <Link
              href="https://www.instagram.com/certifitmotion/"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}>
              <Instagram />
            </Link>
            <Link href="https://www.twitter.com/" color="inherit">
              <CloseIcon />
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="/">
              CertiFit-Motion
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}