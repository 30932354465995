import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Avatar, Typography, Grid, Skeleton } from '@mui/material';

import MenuBar from '../../components/appBar';

const CoachProfile = () => {  
    const { coachUrl } = useParams();
    const [selectedCoach, setSelectedCoach] = useState(null);
   
    const navigate = useNavigate();
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/coachs/${coachUrl}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('La requête a échoué');
          }
          return response.json();
        })
        .then((data) => {
          setSelectedCoach(data);
        })
        .catch((error) => {
          console.error('Erreur lors de la requête GET : ', error);
   
        });
    }, [coachUrl, navigate]);

  return (
    <div>
        <MenuBar/>

     <div >
       { selectedCoach ? (
     <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
            <Avatar style={{width:'40vh', height:'40vh'}} alt={`${selectedCoach?.name} ${selectedCoach?.surname}`} src={`/images/coachs/johndoes.png`} />
      
            </div>
            ): (
              <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
              <Skeleton variant="circular" width={400} height={400} />
              </div>
            )}
                 { selectedCoach ? (
                    <>
                <Typography variant='h6' style={{ textAlign: 'center' }}>{selectedCoach ? selectedCoach.name : ''} {selectedCoach ? selectedCoach.surname : ''}</Typography>
                <Typography style={{textAlign:'center'}}>{selectedCoach ? selectedCoach.description: ''}</Typography>
                  </>
                 ):(
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                  <div>
                    <Skeleton variant="text" width={400}  />
                  </div>
                  <div>
                    <Skeleton variant="text" width={700}  />
                  </div>
                  <div>
                    <Skeleton variant="text" width={700}  />
                  </div>
                 
                </div>
                 )}
        <Grid container spacing={5}>
    <Grid item xs={12} sm={6}>
        <div style={{ margin: '10%', textAlign:'center' }}>
            <h2> Mes Formations </h2>
            <ul>
            { selectedCoach ? (
               <> { selectedCoach && selectedCoach.formations.map((formation, index) => (
                    <ul key={index} style={{ margin: '1%' }}>{formation}</ul>
                ))}</>
            ):(  
               <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                
              <Skeleton variant="text" width={300} />
              <Skeleton variant="text" width={300} />
              <Skeleton variant="text" width={300} />
            </div>)}
            </ul>
        </div>
    </Grid>

    <Grid item xs={12} sm={6}>
        <div style={{ margin: '10%' , textAlign:'center' }}>
            <h2> Mes Contacts </h2>
            <ul>
            { selectedCoach ? (
               <>   { selectedCoach && selectedCoach.contact.map((contact, index) => (
                    <ul key={index} style={{ margin: '1%' }}>{contact}</ul>
                ))}
                </>
            ):(
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>  
              <Skeleton variant="text" width={300} />
              <Skeleton variant="text" width={300} />
              <Skeleton variant="text" width={300} />
            </div>)}
            </ul>
        </div>
    </Grid>
</Grid>

      </div>
    </div>
  );
}

export default CoachProfile;
