import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { TextField, Button, Typography, Box, Paper, ButtonGroup, Container } from "@mui/material";
import ArrowOutward from '@mui/icons-material/ArrowOutward';
import { useTranslation } from 'react-i18next';

import MenuBar from './components/appBar';
import Footer from './components/footer';

export default function Contact() {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
  
    const formData = {
      name,
      email,
      message,
    };
  
    axios.post(`${process.env.REACT_APP_API_URL}/contact/submit`, formData)
      .then((response) => {
        navigate("/");
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const navigateToProduct = () => {
    navigate(`/produit`);
 };

  return (
      <div>
          <MenuBar/>
   
    <Container  maxWidth="l" >
      <div style={{margin: "3%"}}>

        <Typography>
          <Typography variant="h3">
          {t('contactUs')}
          </Typography>
   
         {t('contactPageDesc')} <span style={{color:'rgb(24,118,210)'}} onClick={() => navigateToProduct()}> <b>{t('contactPageDesc2')}</b> </span> {t('contactPageDesc3')} <span style={{color:'rgb(24,118,210)'}} onClick={() => navigateToProduct()}> <b>{t('contactPageDesc4')}</b> </span>
 <br/><b>{t('contactUs2')}</b> 
        </Typography>
      </div>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10vh",
        marginBottom:'5vh'
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: 600,
          mx: "auto",
          p: 2,
    
          boxShadow: 1,
        }}
      >
        <Typography variant="h6" align="center" mb={2}>
          {t('contactForm')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Nom"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            type="email"
          />
          <TextField
            fullWidth
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            margin="normal"
            required
            multiline
            rows={4}
          />
          <Button
            fullWidth
            type="submit"
            onSubmit={handleSubmit}
            sx={{
              mt: 2,
              backgroundColor: "rgb(24,118,210)",
              color: "#fff",
              "&:hover": {
                backgroundColor: "black",
              },
            }}
          >
            {t('send')}
          </Button>
        </form>
      </Paper>
    </Box>
  
    </Container>
    <Container  maxWidth="md" style={{display:'flex', justifyContent:'center'}} >
      <div style={{textAlign:'center', marginBottom:'10vh', width: 620}}>

        <Typography>
        <Typography variant="h4" style={{color:'rgb(24,118,210)'}}>
             <b> {t('sureChoice')} </b>
        </Typography>
        {t('joinCertifit')}

        </Typography>
        <ButtonGroup variant="text" style={{ marginTop:'8%'}} aria-label="Basic button group">
        <Button href="/devis">
        {t('makeQuote')}    <ArrowOutward sx={{ fontSize: '2rem', color: 'rgb(25, 108, 210)' }} />
 
        </Button> 
        <Button   href="/pricing">
                      <ArrowOutward sx={{ fontSize: '2rem', color: 'rgb(25, 108, 210)' }} />
          {t('seeOffers')}
        </Button>
       </ButtonGroup>
      </div>
      </Container>
   
    <Footer/>
    </div>
  );
}
