import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListItem, ListItemText, useTheme, useMediaQuery, Paper, Typography, Container, Skeleton, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import MenuBar from '../appBar';
import Footer from '../footer';
import FilterBar from '../filterBar'; 


export const ListCoachs = () => {
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const skeletonHeight = isSmallScreen ? '10%' : '5%';
    const skeletonspace = isSmallScreen ? '0' : '9vh';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate();
    const [coachs, setCoachs] = useState([]);
    const [filteredCoachs, setFilteredCoachs] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/coachs`)
        .then(response => response.json())
        .then(data => {
            const coachsArray = Object.values(data);
            setCoachs(coachsArray);
            setFilteredCoachs(coachsArray); 
        })
        .catch(error => {
            console.error('Erreur lors de la requête GET : ', error);
        });
    }, []);

    const handleFilter = (query) => {
        const filtered = coachs.filter(coach =>
            coach.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCoachs(filtered);
    };

    const handleListItemClick = (coachid) => {
        navigate(`/coachprofile/${coachid}`);
    };

    return (
        <div>
            <MenuBar/>
            <Container maxWidth="l" style={{padding:'5%'}}>
            <Typography variant="h3" gutterBottom>
                        {t('ourCoaches')}
                    </Typography>
                <Paper elevation={3} style={{ padding: '24px', marginBottom: '24px' }}>
                   
                    <Typography variant="h6" gutterBottom>
                        {t('listCoachesDesc')}
                    </Typography>
                    <FilterBar onFilter={handleFilter} props={t('searchCoach')} />
                </Paper>
                <div>
                    {filteredCoachs.length > 0 ? (
                    <>
                    {filteredCoachs.map(coach => (  
                          <Paper style={{ marginBottom: '24px' }}>
                             {isMobile ? (
                 <ListItem
                 alignItems="flex-start"
                 key={coach.id}
                 onClick={() => handleListItemClick(coach.url)}
                 sx={{
                     "&:hover": {
                         backgroundColor: "#f0f0f0",
                     },
                 }}
             >
                    <img src={`/images/coachs/johndoes.png`} style={{width:'20%', height:'20%', margin:'3%'}}/>
                             
               <ListItemText
                     style={{ marginTop: '3%', fontSize: '14px' }}
                   primary={<Typography variant="h5">{coach.name} {coach.surname}</Typography>}
                     secondary={
                         <React.Fragment>
                             <Typography variant="body2" color="text.primary">
                                 {coach.description}
                            </Typography>
                         </React.Fragment>
                     }
                 />
                   
             </ListItem>
          
         ): (
                               <ListItem
                               alignItems="flex-start"
                               key={coach.id}
                               onClick={() => handleListItemClick(coach.url)}
                               sx={{
                                   "&:hover": {
                                       backgroundColor: "#f0f0f0",
                                   },
                               }}
                           >
                                 <img src={`/images/coachs/johndoes.png`} style={{width:'10%', height:'10%', margin:'3%'}}/>
                               <ListItemText style={{marginTop:'50px'}}
                                   primary={<Typography variant="h5">{coach.name} {coach.surname}</Typography>}
                                   secondary={
                                       <React.Fragment>
                                           <Typography
                                               variant="body2"
                                               color="text.primary"
                                           >
                                               {coach.description}
                                           </Typography>
                                           </React.Fragment>
                                   }
                                   
                               />
                                 
                           </ListItem> 
                     )}
                     </Paper>
                    ))}</>
                    ):(
                    
                        <div>

                           
                            <div style={{ display: 'flex', alignItems: 'center', margin:'5%' }}>
                                <Skeleton variant="circular" width={30} height={30} style={{ padding: `${skeletonHeight}` }} />
                                <Skeleton variant="text" width={150} style={{ marginLeft: '10px' }} />
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', margin:'5%' }}>
                                <Skeleton variant="circular"  width={30} height={30}  style={{ padding: `${skeletonHeight}` }} />
                                <Skeleton variant="text" width={150} style={{ marginLeft: '10px' }} />
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', margin:'5%' }}>
                                <Skeleton variant="circular"  width={30} height={30} style={{ padding: `${skeletonHeight}` }} />
                                <Skeleton variant="text" width={150} style={{ marginLeft: '10px' }} />
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', margin:'5%' }}>
                                <Skeleton variant="circular"  width={30} height={30} style={{ padding: `${skeletonHeight}` }} />
                                <Skeleton variant="text" width={150} style={{ marginLeft: '10px' }} />
                            </div>
                        
                         
                      </div>
                      
                    
                    
                    )}
                    </div>
            </Container>
            <Footer/>
        </div>
    );
};
