import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Switch, FormControlLabel, FormGroup, Container, Grid, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const CreateUserAdmin = () => {
  const [isGym, setIsGym] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    surname: '',
    description: '',
    address: [''],
    formations: [''],
    contacts: [''],
  });

  const handleChange = (event, index = null, field = null) => {
    if (index !== null && field) {
      const updatedArray = [...userData[field]];
      updatedArray[index] = event.target.value;
      setUserData(prevState => ({
        ...prevState,
        [field]: updatedArray,
      }));
    } else {
      const { name, value } = event.target;
      setUserData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAddField = (field) => {
    setUserData(prevState => ({
      ...prevState,
      [field]: [...prevState[field], ''],
    }));
  };

  const handleRemoveField = (index, field) => {
    const updatedArray = [...userData[field]];
    updatedArray.splice(index, 1);
    setUserData(prevState => ({
      ...prevState,
      [field]: updatedArray,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const apiUrl = isGym ? `${process.env.REACT_APP_API_URL}/gyms` : `${process.env.REACT_APP_API_URL}/coachs`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data);
      alert(isGym ? 'Gym created successfully' : 'Coach created successfully');
    } catch (error) {
      console.error('Error:', error);
      alert('Error creating ' + (isGym ? 'gym' : 'coach'));
    }
  };

  const renderDynamicFields = (fieldLabel, fieldName) => (
    userData[fieldName].map((value, index) => (
      <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          fullWidth
          label={fieldLabel}
          value={value}
          onChange={(e) => handleChange(e, index, fieldName)}
          sx={{ mb: 1 }}
        />
        <IconButton onClick={() => handleAddField(fieldName)}>
          <AddCircleOutlineIcon />
        </IconButton>
        {index > 0 && (
          <IconButton onClick={() => handleRemoveField(index, fieldName)}>
            <RemoveCircleOutlineIcon />
          </IconButton>
        )}
      </Box>
    ))
  );

  return (
    <Container maxWidth="xl" style={{ marginTop: '5%' }}>
      <Box
        component="form"
        sx={{ '& .MuiTextField-root': { m: 1 }, }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>{isGym ? 'Create Gym' : 'Create Coach'}</Typography>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isGym} onChange={() => setIsGym(!isGym)} />}
            label={isGym ? 'Switch to Coach' : 'Switch to Gym'}
          />
        </FormGroup>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Name"
              name="name"
              value={userData.name}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              label="Description"
              name="description"
              value={userData.description}
              onChange={handleChange}
              multiline
            />
            {isGym && renderDynamicFields("Address", "address")}
          </Grid>
          <Grid item xs={12} sm={6}>
            {!isGym && (
              <>
                <TextField
                  fullWidth
                  label="Surname"
                  name="surname"
                  value={userData.surname}
                  onChange={handleChange}
                />
                {renderDynamicFields("Formations", "formations")}
              </>
            )}
            {!isGym && renderDynamicFields("Contacts", "contacts")}
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" sx={{ mt: 3 }}>
          Submit
        </Button>
      </Box>
    </Container>
  );
};

export default CreateUserAdmin;
