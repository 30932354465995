import React from 'react';
import { Container, Box, Typography, Button, Grid, Avatar, Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

function OfferPresentationMini() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Grid container spacing={4} alignItems="center">
     

        <Grid item xs={12} md={6}>
          <Typography variant="h3" gutterBottom>
            {t('certifitOffer')}
          </Typography>
          <Typography variant="body1" paragraph>
          {t('certifitOfferDesc')}
          </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h6" gutterBottom>
            {t('attributeDesc')}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
              <Typography>{t('attributeDesc1')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
              <Typography>{t('attributeDesc2')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
              <Typography>{t('attributeDesc3')}</Typography>
            </Box>
          </Box>

          <Box sx={{ mt: 4 }}>
            <Button variant="contained" color="primary" sx={{ mr: 2 }} href="/offer">
            {t('readMore')}
            </Button>
            <Button variant="outlined" color="primary" href="/contact">
            {t('contactUs')}
            </Button>
          </Box>
        </Grid>
      </Grid>
       
      {/*<Box sx={{ mt: 10 }}>
        <Typography variant="h4" gutterBottom>
          Nos clients satisfaits
        </Typography>
        <Grid container spacing={4}>
           Exemple de témoignages 
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar src="/images/content/clients/client1.jpg" alt="Client 1" sx={{ width: 56, height: 56, mr: 2 }} />
              <Typography variant="body1">"CertiFit a transformé ma manière de coacher mes clients !"</Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              - Jean Dupont, Coach sportif
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar src="/images/content/clients/client2.jpg" alt="Client 2" sx={{ width: 56, height: 56, mr: 2 }} />
              <Typography variant="body1">"Une plateforme intuitive et indispensable pour ma salle de sport."</Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              - Sarah Martin, Gérant de salle
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar src="/images/content/clients/client3.jpg" alt="Client 3" sx={{ width: 56, height: 56, mr: 2 }} />
              <Typography variant="body1">"Un suivi des performances à la pointe pour mes séances."</Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              - Laura Dubois, Membre active
            </Typography>
          </Grid>
        </Grid>
      </Box>*/}
    </Container>
  );
}

export default OfferPresentationMini;
