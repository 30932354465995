import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';

const EditExercise = () => {
  const { exerciseName } = useParams();
  const { equipmentUrl} = useParams();
  const navigate = useNavigate();
  const [exercise, setExercise] = useState({

    name: '',
    description: [''],
    tips: [''],
    advantages: [''],
    explications: [''],
    linkVideo: '',
    muscle:'',
    muscles:[''], 
    equipments: equipmentUrl,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/exercices/${exerciseName}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch exercise data');
        }
        return response.json();
      })
      .then(data => {
        setExercise(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [exerciseName, navigate]);

  const handleChange = (field, value) => {
    setExercise(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/exercices/${exercise.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(exercise),
      });
      if (!response.ok) {
        throw new Error('Failed to update exercise');
      }
      alert('Exercise updated successfully');
      navigate(`/exercises/${exerciseName}`);
    } catch (error) {
      console.error('Error:', error);
      alert('Error updating exercise');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6">Edit Exercise</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Name"
          value={exercise.name}
          onChange={(e) => handleChange('name', e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Description"
          multiline
          rows={4}
          value={exercise.description.join('\n')}
          onChange={(e) => handleChange('description', e.target.value.split('\n'))}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Tips"
          multiline
          rows={4}
          value={exercise.tips.join('\n')}
          onChange={(e) => handleChange('tips', e.target.value.split('\n'))}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Advantages"
          multiline
          rows={4}
          value={exercise.advantages.join('\n')}
          onChange={(e) => handleChange('advantages', e.target.value.split('\n'))}
          margin="normal"
        />
             <TextField
          fullWidth
          label="Advantages"
          multiline
          rows={4}
          value={exercise.advantages.join('\n')}
          onChange={(e) => handleChange('advantages', e.target.value.split('\n'))}
          margin="normal"
        />
             <TextField
          fullWidth
          label="Explications"
          multiline
          rows={4}
          value={exercise.explications.join('\n')}
          onChange={(e) => handleChange('explications', e.target.value.split('\n'))}
          margin="normal"
        />
             <TextField
          fullWidth
          label="Video url"
          rows={4}
          value={exercise.linkVideo}
          onChange={(e) => handleChange('linkVideo', e.target.value)}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Muscle principal"
          rows={4}
          value={exercise.muscle}
          onChange={(e) => handleChange('muscle', e.target.value)}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Autres muscles"
          rows={4}
          multiline
          value={exercise.muscles.join('\n')}
          onChange={(e) => handleChange('muscles', e.target.value.split('\n'))}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
          Save Changes
        </Button>
      </form>
    </Box>
  );
};

export default EditExercise;
