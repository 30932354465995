import * as React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuBar from "../content/components/appBar";
import Footer from "../content/components/footer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useParams, useNavigate } from 'react-router-dom';
import { ListExoByEquipment } from "../content/components/lists/listExoByGym";
import CloseIcon from '@mui/icons-material/Close';

export default function CreateExercices() {
const { equipmentUrl } = useParams();
const { gymUrl } = useParams();
const { coachUrl } = useParams();
const [newAdvantage, setNewAdvantage] = useState("");
const [newTip, setNewTip] = useState("");
const [newExplication, setNewExplication] = useState("");
const [newDescription, setNewDescription] = useState("");

  const [open, setOpen] = useState(false);
  const [muscle, setMuscle] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState([]);
  const [linkVideo, setLinkVideo] = useState("");
  const [linkImage, setLinkImage] = useState("");
  const [tips, setTips] = useState([]);
  const [advantages, setAdvantages] = useState([]);
  const [explications, setExplications] = useState([]);
  const [selectedMuscles, setSelectedMuscles] = useState([]); 
  const [selectedMuscle, setSelectedMuscle] = useState(""); 
  const [submitted, setSubmitted] = useState(false);
  const [exercicesData, setExercicesData] = useState([]);
  const [selectedGym, setSelectedGym] = useState(""); 
  const [selectedCoach, setSelectedCoach] = useState(""); 
  const [selectedEquipment, setSelectedEquipment] = useState(""); 
  
  const listMuscles = [
    "pectoraux (pecs)",
    "grand dorsal",
    "trapèzes",
    "épaules (deltoïdes)",
    "biceps",
    "triceps",
    "quadriceps",
    "ischios-jambiers",
    "fessiers",
    "abdominaux",
    "lombaires",
    "érecteurs du rachis",
    "deltoides postérieurs",
    "deltoides antérieurs",
    "deltoides latéraux",
    "trapèze supérieur",
    "trapèze moyen",
    "trapèze inférieur",
    "rhomboïdes",
    "grand rond",
    "petit rond",
    "grand dorsal inférieur",
    "grand dorsal supérieur",
    "biceps brachial",
    "biceps fémoral",
    "biceps crural",
    "triceps brachial",
    "grand oblique",
    "petit oblique",
    "transverse",
    "vaste médial",
    "vaste latéral",
    "vaste intermédiaire",
    "dentelé antérieur",
    "dentelé postérieur",
    "dentelé inférieur",
    "pectoraux supérieurs",
    "pectoraux inférieurs",
    "pectoraux médians",
    "pectoraux inférieurs",
    "carreaux (abdominaux)",
    "obliques",
    "adducteurs",
    "adducteurs de la cuisse",
    "adducteurs de la hanche",
    "adducteurs de l'épaule",
    "mollets",
    "trapèze inférieur",
    "gastrocnémien",
    "soléaire",
    "tibiaux antérieurs",
    "fléchisseurs du poignet",
    "extenseurs du poignet",
    "fléchisseurs de la main",
    "extenseurs de la main",
    "fléchisseurs du coude",
    "extenseurs du coude",
    "fléchisseurs de l'épaule",
    "extenseurs de l'épaule",
  ];
  
  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };


  const handleRemoveExercise = (exercise) => {
    const updatedExercices = exercicesData.filter((item) => item !== exercise);
    setExercicesData(updatedExercices);
  };

  const addSelectedMuscle = () => {
    if (selectedMuscle) {
      setSelectedMuscles([...selectedMuscles, selectedMuscle]);
      setSelectedMuscle(""); 
    }
  };

  const addTip = (newTip) => {
    setTips([...tips, newTip]);
  };

  const addAdvantage = (newAdvantage) => {
    setAdvantages([...advantages, newAdvantage]);
  };

  const addDescription = (newDescription) => {
    setDescription([...description, newDescription]);
  };

  const addExplication = (newExplication) => {
    setExplications([...explications, newExplication]);
  };

  const removeSelectedMuscle = (index) => {
    const updatedSelectedMuscles = [...selectedMuscles];
    updatedSelectedMuscles.splice(index, 1);
    setSelectedMuscles(updatedSelectedMuscles);
  };

  const removeTip = (index) => {
    const updatedTips = [...tips];
    updatedTips.splice(index, 1);
    setTips(updatedTips);
  };

  const removeDescription = (index) => {
    const updatedDescriptions = [...description];
    updatedDescriptions.splice(index, 1);
    setDescription(updatedDescriptions);
  };

  const removeAdvantage = (index) => {
    const updatedAdvantages = [...advantages];
    updatedAdvantages.splice(index, 1);
    setAdvantages(updatedAdvantages);
  };

  const removeExplication = (index) => {
    const updatedExplications = [...explications];
    updatedExplications.splice(index, 1);
    setExplications(updatedExplications);
  };

  const handleSubmitExercice = (event) => {
    setLinkImage("test")
    event.preventDefault();
    const exercice = {
      name: name,
      description: description,
      muscle: muscle,
      muscles: selectedMuscles,
      linkVideo: linkVideo,
      tips: tips,
      advantages: advantages,
      explications: explications,
      equipment_url: equipmentUrl.toLowerCase().replace(/\s/g, "") + selectedGym,
    };
    exercicesData.push(exercice);
    setName("")
    setDescription([])
    setLinkVideo("")
    setMuscle("")
    setSelectedMuscles([]);
    setTips([])
    setAdvantages([])
    setExplications([])
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Exercices à créer :", exercicesData);

    if (!submitted) {
      for (let i = 0; i < exercicesData.length; i++) {
        const exerciceData = exercicesData[i];
        const jsonData = JSON.stringify(exerciceData);
        
        console.log("Exercice à créer :", exerciceData);
        fetch(`${process.env.REACT_APP_API_URL}/exercices`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: jsonData,
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Exercice créé avec succès :", data);
            console.log(exerciceData);
          })
          .catch((error) => {
            console.error("Erreur lors de la création de l'exercice :", error);
          });
      }
    }
  };

        useEffect(() => {
        const fetchEquip = fetch(`${process.env.REACT_APP_API_URL}/equipments/${equipmentUrl}`)
        .then((response) => {
            if (!response.ok) {
            throw new Error('La requête a échoué');
            }
            return response.json();
        })
        .then((data) => {
            setSelectedEquipment(data);
        // sortEquipment(sortCriteria);
        })
        .catch((error) => {
            console.error('Erreur lors de la requête GET : ', error);
        });

        fetchEquip;
    }, [equipmentUrl]);

    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/coachs/${coachUrl}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('La requête a échoué');
          }
          return response.json();
        })
        .then((data) => {
          setSelectedCoach(data);
        })
        .catch((error) => {
          console.error('Erreur lors de la requête GET : ', error);
   
        });
    }, [coachUrl]);



  return (
    <React.Fragment>
      <MenuBar />

      <div style={{ margin: "3%" }}>
        <Paper elevation={3} sx={{ marginRight: "5%", marginLeft: "5%" }}>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h4" gutterBottom textAlign="center">
              Créer un exercice
            </Typography>
          </Box>
          <Box sx={{ padding: 5 }}>
            <form onSubmit={handleSubmit}>

                <Typography>
                 <h2> Salle : {gymUrl}  </h2>  

                 <h2> Machine : {equipmentUrl}  </h2>  
                 <h2> Coach : {selectedCoach.name}  </h2>  
                </Typography>
              <Grid container spacing={3}>
           
                <ListExoByEquipment gymUrl={gymUrl} equipmentUrl={equipmentUrl}/>
        
                <Grid item xs={12} sm={2}>
             
                <Button variant="contained" onClick={handleOpenDialog}>
                  Ajouter un exercice
                </Button>
                </Grid>

                <Dialog open={open} onClose={handleCloseDialog} maxWidth="large" fullWidth>
                  <DialogTitle>Ajouter un exercice</DialogTitle>
                  <DialogContent>
                    <form onSubmit={handleSubmitExercice}>
                      <Grid container spacing={3}>
                      <Grid item xs={12} sm={2}>
                        <InputLabel
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 700,
                          }}
                        >
                          Nom
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <TextField
                          required
                          id="name"
                          name="name"
                          label="nom"
                          fullWidth
                          size="large"
                          autoComplete="off"
                          variant="outlined"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={10}>
              <Typography variant="" gutterBottom>
                  Description
                </Typography>
                <TextField
                  required
                  id="description"
                  name="description"
                  label="description"
                  multiline
                  fullWidth
                  size="large"
                  autoComplete="off"
                  variant="outlined"
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                  sx={{
                    marginTop:'2%',
                    marginBottom:'2%'
                  }}
                />  
                  <Button
                  variant="contained"
                  style={{margin:'5px'}}
                  onClick={() => {
                    addDescription(newDescription);
                    setNewDescription("");
                  }}
                >
                  Ajouter
                </Button>
                </Grid>
            
              </Grid>
                  <Grid item xs={12} sm={4}>
                <Typography variant="" gutterBottom>
                  Description
                </Typography>
                <ul>
                  {description.map((description, index) => (
                    <ul key={index}>
                      {description}{" "}
                      <CloseIcon
                        variant="contained"
                        onClick={() => removeDescription(index)}
                        sx={{
                            marginBottom:'2%'
                        }}
                      />
                    </ul>
                  ))}
                </ul>
    
                    
                      <Grid item xs={12} sm={2}>
                        <InputLabel
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 700,
                          }}
                        >
                          vidéo
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <TextField
                          required
                          id="linkVideo"
                          name="linkVideo"
                          label="url"
                          fullWidth
                          size="small"
                          autoComplete="off"
                          variant="outlined"
                          onChange={(e) => setLinkVideo(e.target.value)}
                        />
                      </Grid>
              <Grid sx={{marginLeft:'12%', marginTop:'3%'}} item xs={12} sm={12}>
              <Grid item xs={12} sm={10}>
              <Typography variant="" gutterBottom>
                  Avantages
                </Typography>
                <TextField
                  required
                  id="advantages"
                  name="advantages"
                  label="avantages"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={newAdvantage}
                  onChange={(e) => setNewAdvantage(e.target.value)}
                  sx={{
                    marginBottom:'2%'
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    addAdvantage(newAdvantage);
                    setNewAdvantage("");
                  }}
                  sx={{
                    marginBottom:'2%'
                  }}
                >
                  Ajouter
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
           
                <ul>
                  {advantages.map((advantage, index) => (
                    <ul key={index}>
                      {advantage}{" "}
                      <CloseIcon
                        variant="contained"
                        onClick={() => removeAdvantage(index)}
                        sx={{
                          marginBottom:'2%'
                        }}
                      />
                    </ul>
                  ))}
                </ul>
              </Grid>
           
              <Grid item xs={12} sm={10}>
              <Typography variant="" gutterBottom>
                  Conseils
                </Typography>
                <TextField
                  required
                  id="tips"
                  name="tips"
                  label="conseil"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={newTip}
                  onChange={(e) => setNewTip(e.target.value)}
                  sx={{
                    marginTop:'2%',
                    marginBottom:'2%'
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    addTip(newTip);
                    setNewTip("");
                  }}
                  sx={{
                    marginBottom:'2%'
                  }}
                >
                  Ajouter
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
         
                <ul>
                  {tips.map((tip, index) => (
                    <ul key={index}>
                      {tip}{" "}
                      <CloseIcon
                        variant="contained"
                        onClick={() => removeTip(index)}
                        sx={{
                          marginBottom:'2%'
                        }}
                      />
                    </ul>
                  ))}
                </ul>
              </Grid>
            
              <Grid item xs={12} sm={10}>
              <Typography variant="" gutterBottom>
                  Explications
                </Typography>
                <TextField
                  required
                  id="explications"
                  name="explications"
                  label="explications"
                  multiline
                  fullWidth
                  size="large"
                  autoComplete="off"
                  variant="outlined"
                  value={newExplication}
                  onChange={(e) => setNewExplication(e.target.value)}
                  sx={{
                    marginTop:'2%',
                    marginBottom:'2%'
                  }}
                />
                </Grid>
                <Button
                  variant="contained"
                  onClick={() => {
                    addExplication(newExplication);
                    setNewExplication("");
                  }}
                >
                  Ajouter
                </Button>
              </Grid>
                  <Grid item xs={12} sm={4}>
                <Typography variant="" gutterBottom>
                  Explications
                </Typography>
                <ul>
                  {explications.map((explication, index) => (
                    <ul key={index}>
                      {explication}{" "}
                      <CloseIcon
                        variant="contained"
                        onClick={() => removeExplication(index)}
                        sx={{
                            marginBottom:'2%'
                        }}
                      />
                    </ul>
                  ))}
                </ul>
    
              <Grid item xs={12} sm={10}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">Muscle principale</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={muscle}
                            label="muscle"
                            onChange={(e) => setMuscle(e.target.value)}
                          >
                            {listMuscles.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    
                      <Grid item xs={12} sm={10}>
                      <FormControl fullWidth>
                      <InputLabel>Choisir les muscles ciblés</InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="muscle"
                          value={selectedMuscle}
                          onChange={(event) => setSelectedMuscle(event.target.value)}
                        >
                          {listMuscles.map((muscle) => (
                            <MenuItem key={muscle} value={muscle}>
                              {muscle}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Button variant="contained" onClick={addSelectedMuscle}>
                        Ajouter
                      </Button>
                    </Grid>
                  
                      <Grid item xs={12} sm={10}>
                    <Typography variant="" gutterBottom>
                      Muscles sélectionnés
                    </Typography>
                    <ul>
                  {selectedMuscles.map((muscle, index) => (
                  <li key={index}>{muscle}
                  <CloseIcon
                      variant="contained"
                      onClick={() => removeSelectedMuscle(index)}
                    />
                    </li>
                ))}
                    </ul>
                  </Grid>
                  </Grid>
                  </Grid>
                    </form>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                      Annuler
                    </Button>
                    <Button onClick={handleSubmitExercice} color="primary">
                     Ajouter l'exercice
                    </Button>
                  </DialogActions>
                </Dialog>
                <Grid item xs={12} sm={10}>
             
                  <Typography variant="" gutterBottom>
                    Liste des Exercices Ajoutés
                  </Typography>
                  <ul>
                    {exercicesData.map((exercise, index) => (
                      <li key={index}>
                        {exercise.name}{" "}
                        <CloseIcon
                          variant="contained"
                          onClick={() => handleRemoveExercise(exercise)}
                        />
                      </li>
                    ))}
                  </ul>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Button type="submit" variant="contained" sx={{ color: "#ffff" }}>
                    Enregistrer
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </div>
      <Footer />
    </React.Fragment>
  );
}