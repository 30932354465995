import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { List, ListItemText, ListItem, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
export const ListExoByCoach = (props) => {
    const { listExo = [] } = props;
    const [exercices, setExercicesData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchExercices = async () => {
            const fetchedExercices = [];
            for (const exo of listExo) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/exercices/id/${exo}`);
                    if (!response.ok) {
                        throw new Error('Failed to fetch exercise');
                    }
                    const data = await response.json();
                    fetchedExercices.push(data);
                } catch (error) {
                    console.error('Erreur lors de la requête GET : ', error);
                }
            }
            setExercicesData(fetchedExercices);
        };
        fetchExercices();
    }, [listExo]);

    const handleListItemClick = (exercice) => {
        navigate(`/editExercice/${exercice.url}/${exercice.name}`);
    };

    return (
        <div>
            <Container maxWidth="md" style={{ padding: '5%' }}>
                <h2> Liste des exercices créer ( cliquer pour modifier )</h2>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', maxHeight: '400px', overflow: 'auto' }}>
                    {exercices.map((exercice, index) => (
                        <ListItem
                            alignItems="flex-start"
                            key={index}
                            onClick={() => handleListItemClick(exercice)}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                },
                            }}
                        >
                            <ListItemText
                                primary={exercice?.name}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {/* Add secondary text here */}
                                        </Typography>
                                        <br/>
                                        <Divider/>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </Container>
        </div>
    );
};
