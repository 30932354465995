import React, { useState, useEffect } from 'react';
import { CardActionArea, Card, Typography, CardMedia, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function MuscleCard({ muscle }) { // Destructure props here
    const [selectedMuscle, setSelectedMuscle] = useState(null);
    const [isLoading, setIsLoading] = useState(true); 
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!muscle) return; // Prevent running fetch if muscle is not defined
        setIsLoading(true); 
        fetch(`${process.env.REACT_APP_API_URL}/muscles/${muscle}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error('La requête a échoué');
            }
            return response.json();
          })
          .then((data) => {
            setSelectedMuscle(data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error('Erreur lors de la requête GET : ', error);
            setIsLoading(false); // Stop loading on error
          });
      }, [muscle]);
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (!selectedMuscle) {
      return <div>No muscle data available.</div>;
    }
  
    return (
      <Card sx={{ maxWidth: 345 }}>
        <CardActionArea onClick={() => navigate(`/muscles/${selectedMuscle.url}`)}>
          <CardMedia
            component="img"
            height="140"
            image={`/images/muscles/${selectedMuscle.url}.png`}
            alt={selectedMuscle.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {selectedMuscle.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
}

