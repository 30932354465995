import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MenuBar from '../appBar';
import Footer from '../footer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import FilterBar from '../filterBar';

export const ListGymsForCoachs = (props) => {
    const {coachUrl} = props;
    const [gyms, setGyms] = useState([]);
    const [filteredGyms, setFilteredGyms] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/gyms`)
        .then(response => response.json())
        .then(data => {
            const gymsArray = Object.values(data);
            setGyms(gymsArray);
            setFilteredGyms(gymsArray);
        })
        .catch(error => {
            console.error('Erreur lors de la requête GET : ', error);
        });
    }, []);

    const handleFilter = (query) => {
        const filtered = gyms.filter(gym =>
            gym.name.toLowerCase().includes(query.toLowerCase()) ||
            gym.description.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredGyms(filtered);
    };

    const handleListItemClick = (gymid) => {
        navigate(`/listEquipByGym/${coachUrl}/${gymid}`);
    };

    return (
        <div>
            <h3>  Ajouter un exercice dans une salle : </h3>
               
                    <FilterBar onFilter={handleFilter}props={'Rechercher sa salle...'} />
            
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {filteredGyms.map((gym, index) => (
                        <ListItem
                            alignItems="flex-start"
                            key={index}
                            onClick={() => handleListItemClick(gym.url)}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                },
                            }}
                        >
                            <ListItemText
                                primary={gym.name}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {gym.description}
                                        </Typography>
                                        <Divider />
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
           
        </div>
    );
};
